import React from "react"

const ScrollButton = () => (
  <button
    id="scroll-btn"
    className="sidebar-btns"
    title="Scroll to top"
  > </button>
)

export default ScrollButton
