import { Link } from "gatsby"
import React, { useState } from "react"
import PropTypes from "prop-types"
import Hamburger from "./hamburger"
import MobileMenu from "./mobile-menu"
// import Button from "./button"
// import PaypalButton from './paypal-button'
import { AnchorLink } from "gatsby-plugin-anchor-links"

const Header = ({ siteTitle }) => {
  const [openMobileMenu, setOpenMobileMenu] = useState(false);

  return (
    <header className="header">
      <nav className="mobile-nav">
        <Link to="/">
          <h1 className="mobile-nav__diana-webb">Diana Webb</h1>
        </Link>
        <Hamburger
          openMobileMenu={openMobileMenu}
          setOpenMobileMenu={setOpenMobileMenu}
        />
        <MobileMenu
          openMobileMenu={openMobileMenu}
          setOpenMobileMenu={setOpenMobileMenu}
        />
      </nav>
      <nav className="desktop-nav">
        <div className="desktop-nav__inner-container">
          <ul className="desktop-nav-list">
            <li>
              <Link className="desktop-nav-list__list-item-link" to="/">
                <h1 className="desktop-nav-list__list-item-header">
                  Diana Webb
                </h1>
              </Link>
            </li>
            <li className="desktop-nav-list__list-item">
              <AnchorLink className="desktop-nav-list__list-item-link" to="/#bio">
                <p className="desktop-nav-list__list-item-text">Bio</p>
              </AnchorLink>
            </li>
            <li className="desktop-nav-list__list-item">
              <Link className="desktop-nav-list__list-item-link" to="/excerpt/">
                <p className="desktop-nav-list__list-item-text">
                  Read An Excerpt
                </p>
              </Link>
            </li>
            <li className="desktop-nav-list__list-item">
              <AnchorLink className="desktop-nav-list__list-item-link" to="/#video">
                <p className="desktop-nav-list__list-item-text">
                  Watch a Video
                </p>
              </AnchorLink>
            </li>
          </ul>
          <AnchorLink className="desktop-nav-list__list-item-text" to="/#book-order-id">
            {/* <Button /> */}
            <div className="btn pointer"
            >Order the Book</div>
            {/* Order the Book */}
          </AnchorLink>
          {/* <PaypalButton /> */}
        </div>
      </nav>
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
